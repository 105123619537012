import React from "react"
import PropTypes from "prop-types"

const RoomsPage = ({user, rooms}) => {
  console.log(user)
  console.log(rooms)
  return (
    <div className="container">
      <h1 className="title is-3">Hotel Rooms</h1>
      <div className="columns is-multiline">
        {rooms.map((room, index) => (
          <div key={index} className="column is-one-quarter">
            <div className="card">
              <div className="card-image">
                <figure className="image is-4by3">
                  <img src="https://placehold.co/600x400" alt={room.name} />
                </figure>
              </div>
              <div className="card-content">
                <p className="title is-5">{room.name}</p>
                <p className="subtitle is-6">Internal Address: {room.internal_address}</p>
                <p className="subtitle is-6">Property Address: {room?.property?.name}</p>
                { room?.price?.amount !== undefined && <p className="subtitle is-6">Price: {room?.price?.amount}</p> }
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}


export default RoomsPage
