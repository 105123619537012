import React, { useState } from "react";

import { usePromise } from "../../../hooks/usePromise"
import { Client } from "../../../utils/Client";

function StayGuestInfo({stayGuestId}) {
  const {loading, result, error, refresh} = usePromise(Client.getStayGuest, {stayGuestId}, {})
  return (
    <tr>
      <td>{result.id}</td>
      <td>{result.name}</td>
      <td>{result.checkedIn ? "yes" : "no"}</td>
      <td>{result.checkedOut ? 'yes' : 'no'}</td>
      <td>{result.eta}</td>
      <td>{result.early_check_in_requested}</td>
      <td>{result.email}</td>
    </tr>
  )
}

function UnitName({unitId}) {
  const {loading, result, error, refresh} = usePromise(Client.getUnit, {unitId}, { stayGuestIds: []})
  const { name, internalAddress } = result;

  return (
    <h1 className="subtitle">{name} - {internalAddress}</h1>
  )
}

function StayUnitInfo({stayUnitId}) {
  const {loading, result, error, refresh} = usePromise(Client.getStayUnit, {stayUnitId}, { stayGuestIds: []})
  const { stayGuestIds, unitId } = result;
  return (
    <div className="box content">
      {unitId && <UnitName unitId={unitId} />}
      <div className="table-container">
        <div className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>guest</th>
              <th>checked in</th>
              <th>checked out</th>
              <th>arrival time</th>
              <th>requested early check in</th>
              <th>email</th>
            </tr>
          </thead>
          <tbody>
            {stayGuestIds.map((stayGuestId, index) => (
              <StayGuestInfo
                stayGuestId={stayGuestId}
                key={index}
              />
            ))}
          </tbody>
        </div>
      </div>
    </div>
  )
}

function ExpandableRow({stay, ...props}) {
  const [isExpanded, setIsExpanded] = useState(false);

  const roomInfoRows = stay.stayUnitIds.map(stayUnitId => {
    return <StayUnitInfo
      stayUnitId={stayUnitId}
    />
  })

  return (
    <>
      <tr>
        <td>
          {stay.allCheckedIn ? 'yes' : 'no'}
        </td>
        <td>
          {stay.startDate}
        </td>
        <td>
          {stay.endDate}
        </td>
        <td>
          {stay.stayGuestIds.length}
        </td>
        <td>
          {stay.stayUnitIds.length}
        </td>
        <td>
        </td>
        <td>
          <button className="button is-small is-info" onClick={() => {
              window.location.href = '/stay'
          }}>
            details
          </button>
        </td>
        <td>
          <button className="button is-small is-info" onClick={(e) => {
            setIsExpanded(!isExpanded);
          }}>
            <span className="icon">
              { isExpanded ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i> }
            </span>
          </button>
        </td>
      </tr>
      {isExpanded && (
        <tr>
          <td colSpan="100%">
            <div className="box">
              <div className="content">
                <h1 className="subtitle">Stay Information</h1>
                <div className="columns">
                  <div className="column">
                    <button className="button is-primary" onClick={() => {
                      navigator.clipboard.writeText(`https://demo.annwn.app/#/check_in?check_in_token=${stay.checkInToken}`)
                    }}>
                      Click to copy check in link
                    </button>
                  </div>
                  <div className="column">
                    <p>Check in link:<br/> {`https://demo.annwn.app/#/check_in?check_in_token=${stay.checkInToken}`}</p>
    
                  </div>
                </div>
              </div>
            </div>
            {roomInfoRows}
          </td>
        </tr>
      )}
    </>
  );
}

function DayTable({day}) {
  const {loading, result, error, refresh} = usePromise(Client.getDay, {day}, {stays: []})
  const { stays } = result;
  return (
    <div className="table-container">
      <table className="table is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th>checked in</th>
            <th>check in date</th>
            <th>check out date</th>
            <th>number of guests</th>
            <th>number of rooms</th>
            <th>notes</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {stays.map((stay, index) => (
            <ExpandableRow
              stay={stay}
              key={index}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default DayTable
