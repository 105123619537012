import React from "react"
import PropTypes from "prop-types"

const RatesPage = (props) => {
  return (
    <React.Fragment>
      hi
    </React.Fragment>
  )
}


export default RatesPage
