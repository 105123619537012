import React, { useState } from "react"

import { Scale } from "../../utils/enums"

import DayTable from "./GuestTable/DayTable"

const GuestTable = ({}) => {
  const [scale, setScale] = useState(Scale.DAY);

  const selectorButtons = Object.values(Scale).map((s, index) => {
    return (
      <button key={index} className={"button" + (scale === s ? " is-primary" : "")} disabled={s !== Scale.DAY && s !== Scale.WEEK} onClick={() => setScale(s)}>{s}</button>
    )
  })

  const Switch = () => {
    if (scale === Scale.DAY) {
      return <DayTable day="30-03-2024"/>
    } else {
      return <div>Coming Soon!</div>
    }
  }
  return (
    <>
      <div className="buttons has-addons">
        {selectorButtons}
      </div>
      <Switch />
    </>
  )
}

export default GuestTable
