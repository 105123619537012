import React from 'react';


const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="columns is-mobile">
        <div className="column has-text-left">
          <p>Copyright © {currentYear} Casa Oliva - All Rights Reserved</p>
        </div>
        <div className="column has-text-right flex-container">
          <p>Built with <a href="https://stay.annwn.app">annwn stay</a><img className="icon-image" src="https://stay.annwn.app/images/icon.png" /></p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
