import React from 'react';

const PricingSchemas = ({pricingSchemas}) => {
  if (pricingSchemas === null) {
    return <></>
  } else {
    return (
      <>
        <h1 className="title">Pricing Schemas</h1>
        { pricingSchemas.map((schema, index) => (
          <div className="column is-one-third" key={index}>
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  {schema.currency} {schema.amount}
                </p>
              </header>
              <div className="card-content">
                <div className="content">
                  <p>Rooms: </p>
                  <p>Bookings: </p>
                </div>
              </div>
              <footer className="card-footer">
                <a href="#more-details" className="card-footer-item">More Details</a>
              </footer>
            </div>
          </div> 
        ))}
      </>
    )
  }
}

export default PricingSchemas;
