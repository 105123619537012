// src/components/PrivacyNotice.js
import React, { useEffect, useState } from 'react';

import Cookies from 'js-cookie';

import initMixpanel from '../utilities/mixpanel.js';

const PRIVACY_COOKIE = 'privacy_cookie';

const PrivacyNotice = ({pt}) => {
  const mixpanel = initMixpanel('c9bff0b18fbc20943f53c881fc30e715');
  console.log(mixpanel)
  const [isVisible, setIsVisible] = useState(false);

  const handleClose = () => {
    setIsVisible(false);
    Cookies.set(PRIVACY_COOKIE, 'false', { expires: 90 })
  };

  const handleAccept = () => {
    // Handle the acceptance logic here, e.g., set a cookie or localStorage item
    setIsVisible(false);
    Cookies.set(PRIVACY_COOKIE, 'true', { expires: 90 })
  };

  useEffect(() => {
    const privacy = Cookies.get(PRIVACY_COOKIE)

    if (privacy == undefined) {
      setIsVisible(true);
    } else if (privacy == 'true') {
      mixpanel.opt_in_tracking();
      const script = document.createElement('script');
      script.src = 'https://static.sojern.com/utils/sjrn_autocx.js';
      script.async = true;
      document.body.appendChild(script);

      // Inline script to run after the external script is loaded
      script.onload = () => {
        const inlineScript = document.createElement('script');
        inlineScript.type = 'text/javascript';
        inlineScript.innerHTML = `
          (function () {
            var params = {
              hpr: "Casa Oliva",
              hpid: "260468",
              sha256_eml: "",
              sha1_eml: "",
              md5_eml: "",
              ccid: "",
              pt: "${pt}"
            };

            params.et = {"HOME_PAGE":null,"SEARCH":"hs","PRODUCT":"hpr","SHOPPING_CART":"hcart","CONVERSION":"hc","TRACKING":null}[params.pt] || '';
            try{params = Object.assign({}, sjrn_params, params);}catch(e){}
            var paramsArr = [];
            for(key in params) { paramsArr.push(key + '=' + encodeURIComponent(params[key])) };

            var pl = document.createElement('iframe');
            pl.type = 'text/html';
            pl.setAttribute('style','height:0; width: 0; display:none;');
            pl.async = true;
            pl.src = "https://static.sojern.com/cip/c/45.html?f_v=cp_v3_js&p_v=1&" + paramsArr.join('&');
            (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(pl);
          })();
        `;
        document.body.appendChild(inlineScript);
      };
      setIsVisible(false);
    } else {
      setIsVisible(false);
    }
  }, [])

  if (!isVisible) return null;

  return (
    <div className="privacy-notice">
      <button className="delete top-right is-large" aria-label="close" onClick={handleClose}></button>
      <div className="privacy-content">
        <h3 className="subtitle">Privacy Notice</h3>
        <p style={{fontSize: 'small'}}>
          Our website uses tracking technologies to enhance your browsing experience and provide personalized ads.
          This may involve collecting information about your visit, such as pages viewed, visit time, IP address, and browser details.
        </p>
        <p style={{fontSize: 'small'}}>
          For more information on how we handle your data, please see our <a href="https://stay.annwn.app/terms_and_conditions_privacy_policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
        </p>
        <button className="accept-button" onClick={handleAccept}>Accept</button>
      </div>
    </div>
  );
};

export default PrivacyNotice;
