import React, { useEffect, useState } from "react";

import Cookies from 'js-cookie';

// TODO maybe we need to make this also named with the org or property? so there's no conflicts?
const LANDING_COOKIE = 'landing_cookie';

const LandingModal = ({}) => {
  const [isModalActive, setIsModalActive] = useState(false);

  const toggleModal = () => {
    setIsModalActive(!isModalActive);
    Cookies.set(LANDING_COOKIE, 'true', { expires: 90 })
  }

  useEffect(() => {
    const landing = Cookies.get(LANDING_COOKIE)

    if (landing == undefined) {
      setIsModalActive(true);
    }
  }, [])

  return (
    <div className={`modal ${isModalActive ? 'is-active' : ''}`}>
      <div className="modal-background" ></div>
      <div className="modal-card rounded-modal">
        <section className="modal-card-body">
          <figure className="image">
            <img src="https://stay.annwn.app/images/casa_oliva/room_balcony.webp" alt="Stay with us" />
            <button className="delete top-right is-large" aria-label="close" onClick={toggleModal}></button>
          </figure>
          <div className="modal-content">
            <h1 className="title">Stay with us</h1>
          </div>
        </section>
      </div>
    </div>
  )
}

export default LandingModal
