import React, { useState, useEffect } from "react";

import './Navbar.css';

const Navbar = ({property}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  }

  return (
    <nav className={`navbar ${isScrolled ? 'is-shrunk' : 'is-large'} is-fixed-top`}>
      <div className="navbar-brand" style={{ height: '100%'}}>
        <a className="navbar-item" href="/">
          <img
            src="https://stay.annwn.app/images/casa_oliva/word_logo.png"
            alt="Logo"
            className={`logo ${isScrolled ? 'is-shrunk-logo' : 'is-large-logo'}`}
          />
        </a>
        <div
          className={`navbar-burger burger ${isSidebarOpen ? 'is-active' : ''}`}
          onClick={toggleSidebar}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div className="navbar-menu">
        <div className="navbar-end">
          <a className="navbar-item" href="/">Meet us</a>
          <a className="navbar-item" href="/find_us">Find us</a>
          <a className="navbar-item" href="/stay_with_us">Stay with us</a>
          <a className="navbar-item" target="_blank" href={property.instagram_url}>Instagram</a>
        </div>
      </div>

      <div className={`sidebar ${isSidebarOpen ? 'is-open' : ''}`}>
        <a className="navbar-item" href="/" onClick={toggleSidebar}>Meet us</a>
        <a className="navbar-item" href="/find_us" onClick={toggleSidebar}>Find us</a>
        <a className="navbar-item" href="/stay_with_us" onClick={toggleSidebar}>Stay with us</a>
        <a className="navbar-item" target="_blank" href={property.instagram_url} onClick={toggleSidebar}>Instagram</a>
      </div>
      <div className={`sidebar-overlay ${isSidebarOpen ? 'is-open' : ''}`} onClick={toggleSidebar}></div>
    </nav>
  )
}

export default Navbar
