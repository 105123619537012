import React from "react"

import GuestTable from "./annwn/GuestTable"

const Root = ({user}) => {
  if (user.organization_member !== null && user.organization_member.role === 'admin' ) {
    return (
      <div className="section">
        <GuestTable />
      </div>
    )
  } else if (user.organization_member !== null && user.organization_member.role === 'admin' ) {
    return (
      <div className="section">
      </div>
    )
  } else {
    return (
      <div>👀 what are you doing here?</div>
    )
  }
}


export default Root
