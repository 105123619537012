import React, {useState, useEffect} from "react";
 
export function usePromise(fn, args, def) {
  const [loading, setLoading] = useState(true)
  const [result, setResult] = useState(def)
  const [error, setError] = useState()

  const func = async () => {
    const res = await fn(args)
    setResult(res.data)
    setLoading(false)
  }

  useEffect(() => {
    func().catch(err => setError(err))
  }, [fn])

  const refresh = () => {
    func().catch(err => setError(err))
  }

  return {loading, result, error, refresh}
}
