import Axios from 'axios';
import applyConverters from 'axios-case-converter';

const csrfElement = document.querySelector('meta[name="csrf-token"]')
const orgId = document.querySelector('meta[name="org-id"]')

const baseConfig = {
  baseURL: '/api',
  timeout: 60 * 1000,
  headers: {
    'X-CSRF-Token': csrfElement.content,
    'Org-Id': orgId?.content,
  }
}

export const baseClient = applyConverters(Axios.create(baseConfig))

export class Client {
  static getSession({email, password}) {
    return baseClient.post('/auth/sessions', {email, password})
  }
  static destroySession() {
    return baseClient.delete('/auth/sessions/0')
  }

  static getDay({day}) {
    return baseClient.get(`/stays?day=${day}`)
  }

  static contactUs({email, message}) {
    return baseClient.post('/contact_us', {email, message})
  }

  static getStayUnit({stayUnitId}) {
    return baseClient.get(`/stay_units/${stayUnitId}`)
  }

  static getStayGuest({stayGuestId}) {
    return baseClient.get(`/stay_guests/${stayGuestId}`)
  }

  static getUnit({unitId}) {
    return baseClient.get(`/units/${unitId}`)
  }

  static sendMagicLink({email}) {
    return baseClient.post('/auth/magic_link', { email })
  }

  static updateEmailTemplate({id, values, mjmlString, token}) {
    return baseClient.put(`/email_templates/${id}`, {
      "editor_json": values,
      "mjml_code": mjmlString,
    },
    {
      headers: {
        "Authorization": `Bearer ${token}`,
      }
    })
  }
}
