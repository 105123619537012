import React from "react"
import PropTypes from "prop-types"

const TermsAndConditionsPrivacyPolicy = () => (
  <section className="section">
    <div className="container">
      <h1 className="title">Terms and Conditions</h1>
      <div className="content">
        <h2>1. Introduction</h2>
        <p>
          Welcome to annwn stay, a mobile and web application (the “App”) provided by annwn inc. (“we”, “us”, “our”, “Company”). By using the App, you agree to comply with and be bound by the following terms and conditions (the “Terms”). These Terms govern your access to and use of the App, which facilitates the registration, check-in, and check-out processes for hotel guests. If you do not agree to these Terms, you must not use the App.
        </p>

        <h2>2. Company Information</h2>
        <p>
          Name: annwn inc.<br />
          Registered Address: 2234 Nida Court, Mississauga, ON L4X1J8, Canada<br />
          Contact Information: Email: <a href="mailto:legal@annwn.app">legal@annwn.app</a>
        </p>

        <h2>3. Age Restrictions and Availability</h2>
        <p>The App is intended for use by individuals who are at least 18 years old. The App is available globally, except in countries where Canadian companies are prohibited from doing business.</p>

        <h2>4. User Registration and Identity Verification</h2>
        <p><strong>4.1. Information Collected:</strong> During the registration process, we collect your name and email address. For the main guest staying at the hotel, we also collect identity verification data through our contracted service provider, Veriff. This includes identity documents and a photo of your face.</p>
        <p><strong>4.2. Identity Verification:</strong> You will verify your identity through Veriff by taking photos of your identification documents and a photo of your face. Veriff will collect and store this data, which we will access only for operational reasons. We will not sell or share this data with any third parties other than Veriff and the hotel we are partnered with.</p>

        <h2>5. User Conduct</h2>
        <p>Users must not attempt to hack or interfere with the App unless expressly given permission by us. Unauthorized access or interference may result in account suspension or termination.</p>

        <h2>6. Data Privacy and Security</h2>
        <p><strong>6.1. Data Collection and Retention:</strong> We collect and retain user data for as long as the account is active. Users can request the deletion of their data by sending an email to <a href="mailto:privacy@annwn.app">privacy@annwn.app</a>.</p>
        <p><strong>6.2. Data Sharing:</strong> We will share user data with the partnered hotel and any parties the hotel chooses to share the data with. We will not share user data with any other third parties.</p>
        <p><strong>6.3. Third-Party Cookies and Tracking:</strong> Our hospitality partners may add third-party cookies and tracking links to enhance the user experience. Users have the right to opt out of such tracking in accordance with privacy regulations. Information on how to opt out will be provided within the App.</p>

        <h2>7. Liability and Disclaimers</h2>
        <p><strong>7.1. Limited Liability:</strong> To the maximum extent permitted by law, our liability in connection with the App is limited to the extent appropriate for an application facilitating check-in and check-out processes. The hotel operator assumes liability for all other matters related to the stay.</p>
        <p><strong>7.2. Disclaimers:</strong> We do not warrant the accuracy or completeness of any information provided by the App.</p>

        <h2>8. Changes to Terms and Conditions</h2>
        <p>We reserve the right to update or change these Terms at any time without prior notice. Changes to the Terms will apply to your use of the App from the moment they are posted. It is your responsibility to review these Terms regularly. Continued use of the App after any such changes constitutes your acceptance of the new Terms. The Terms applicable to your stay will remain in effect until you check out, after which any updated Terms will apply.</p>

        <h2>9. Governing Law</h2>
        <p>These Terms are governed by and construed in accordance with the laws of Canada. Any disputes arising out of or relating to these Terms shall be subject to the exclusive jurisdiction of the courts of Canada.</p>

        <h2>10. Contact Information</h2>
        <p>If you have any questions or need support regarding these Terms, please contact us at <a href="mailto:legal@annwn.app">legal@annwn.app</a>.</p>

        <h2>11. App Versions</h2>
        <p>These Terms apply to all versions of the App, including the web app, iOS app, and Android app.</p>
      </div>

      <h1 className="title">Privacy Policy</h1>
      <div className="content">
        <h2>1. Introduction</h2>
        <p>This Privacy Policy explains how annwn inc. (“we”, “us”, “our”) collects, uses, discloses, and protects your information when you use our app, annwn stay (the “App”).</p>

        <h2>2. Information We Collect</h2>
        <p><strong>Personal Information:</strong> Name, email address, identity verification data (including identification documents and photos) collected through Veriff.</p>
        <p><strong>Usage Data:</strong> Information about your interactions with the App, such as access times, device information, and IP addresses.</p>

        <h2>3. How We Use Your Information</h2>
        <p><strong>Operational Purposes:</strong> To facilitate user registration, identity verification, and the check-in/check-out process.</p>
        <p><strong>Communication:</strong> To send administrative information, respond to inquiries, and provide customer support.</p>
        <p><strong>Compliance:</strong> To comply with legal obligations and enforce our terms and conditions.</p>

        <h2>4. How We Share Your Information</h2>
        <p><strong>With Partners:</strong> We share your data with the partnered hotel and any parties the hotel chooses to share the data with.</p>
        <p><strong>With Service Providers:</strong> We share information with Veriff for identity verification purposes.</p>
        <p><strong>Legal Requirements:</strong> We may disclose information if required by law or in response to valid requests by public authorities.</p>

        <h2>5. Data Retention</h2>
        <p>We retain personal information for as long as your account is active or as needed to provide you with our services. You can request data deletion by contacting <a href="mailto:privacy@annwn.app">privacy@annwn.app</a>.</p>

        <h2>6. Data Security</h2>
        <p>We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, use, or disclosure.</p>

        <h2>7. Your Rights</h2>
        <p><strong>Access and Correction:</strong> You can request access to or correction of your personal information.</p>
        <p><strong>Data Deletion:</strong> You can request the deletion of your data by contacting <a href="mailto:privacy@annwn.app">privacy@annwn.app</a>.</p>

        <h2>8. Third-Party Cookies and Tracking</h2>
        <p>Our hospitality partners may use third-party cookies and tracking links. Users can opt out of these tracking mechanisms in compliance with applicable privacy regulations. Information on how to opt out will be provided within the App.</p>

        <h2>9. Changes to this Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we encourage you to review the policy periodically.</p>

        <h2>10. Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:privacy@annwn.app">privacy@annwn.app</a>.</p>
      </div>
    </div>
  </section>
);

export default TermsAndConditionsPrivacyPolicy;
