import React from "react"
import PropTypes from "prop-types"

import Navbar from './property_page/Navbar.js';
import Footer from './property_page/Footer.js';
import './StayWithUs.css';

import LandingModal from './property_page/LandingModal.js';
import PrivacyNotice from './property_page/PrivacyNotice.js';

const StayWithUs = ({property}) => {
  return (
    <React.Fragment>
      <PrivacyNotice pt="PRODUCT"/>
      <LandingModal />
      <Navbar
        property={property}
      />
      <section className="section intro" style={{paddingTop: '10em'}}>
        <img src="https://stay.annwn.app/images/casa_oliva/stay_with_us.webp" alt="Intro" className="intro-image" />
      </section>
      <section className="section">
        <iframe src="https://rbe.zaviaerp.com/?hotel=casaoliva" width="100%" height="1800px"></iframe>
      </section>
      <Footer />
    </React.Fragment>
  )
}


export default StayWithUs
