import mixpanel from 'mixpanel-browser';

const initMixpanel = (mixpanelKey) => {
  mixpanel.init(mixpanelKey, {
    opt_out_tracking_by_default: true,
    track_pageview: true
  });

  return mixpanel;
}

export default initMixpanel;
