import React from 'react';
import { useForm } from 'react-hook-form';

import { Client } from "../../utils/Client";

import initMixpanel from '../utilities/mixpanel.js';

const ContactForm = ({}) => {
  const mixpanel = initMixpanel('8228216aa14fcbb07db16364906636b6');
  const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm();
  const onSubmit = data => {
    mixpanel.track('Contact Us Form Submitted', { data: data })
    // Trigger the Google Analytics event
    window.gtag('event', 'conversion', {
      send_to: 'AW-16647336377/mqp9CKy89MMZELnjiII-',
      /*
      value: 1.0,
      currency: 'MXN'
      */
    });
    Client.contactUs(data).then(() => {
      reset()
    });
  };

  return (
    <section className="section" id="contact-us">
      <div className="columns is-align-items-center">
        <div className="column">
          <p className="title has-text-centered">get in touch for a free property website</p>
          <form onSubmit={handleSubmit(onSubmit)} className="box">
            <div className="field">
              <label className="label">Email</label>
              <div className="control has-icons-left">
                <input
                  className={`input ${errors.email ? 'is-danger' : ''}`}
                  type="email"
                  placeholder="Your email"
                  {...register('email', { required: true })}
                />
                <span className="icon is-small is-left">
                  <i className="fa-solid fa-envelope"></i>
                </span>
                {errors.email && (
                  <p className="help is-danger">Email is required</p>
                )}
              </div>
            </div>
            <div className="field">
              <label className="label">Message</label>
              <div className="control">
                <textarea
                  className={`textarea ${errors.message ? 'is-danger' : ''}`}
                  placeholder="Your message"
                  {...register('message', { required: true })}
                ></textarea>
                {errors.message && (
                  <p className="help is-danger">Message is required</p>
                )}
              </div>
            </div>
            <div className="field is-grouped is-grouped-centered">
              <div className="control">
                <button type="submit" disabled={!isValid} className="button is-primary">
                  Send Message
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="column">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-half">
                <p className="title has-text-centered">contact us</p>
                <p className="subtitle has-text-centered">
                  please contact us for a free website consult. we will work with you to design a property website. we also provide general tech consulting for any other needs. email us at info@annwn.app
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;

