import React from "react";

const Image = ({path, className, alt}) => {
  const production = true;
  const url = production ? 
    `https://d29grgda2r0skz.cloudfront.net/testing/${path}` :
    `https://stay.annwn.app/images/${path}`

  return (
    <img src={url} alt={alt} className={className} />
  )
}

export default Image;
