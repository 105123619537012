import React from "react"
import PropTypes from "prop-types"

import ContactForm from "./annwn/ContactForm"
import PrivacyNotice from "./annwn/PrivacyNotice"

import "../stylesheets/annwn.scss"

const LandingPage = ({}) => {
  return (
    <div>
      {/* Top Section with Photo and Text */}
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <figure className="image">
                <img src="/images/logo.png" alt="Placeholder" />
              </figure>
            </div>
            <div className="column">
              <h1 className="title" style={{direction: 'rtl', textAlign: 'justify'}}>complete<br />technology<br/>partner</h1>
            </div>
          </div>
        </div>
      </section>

      {/* Hero Section */}
      <section className="hero is-medium is-primary">
        <div className="hero-body">
          <div className="container has-text-centered">
            <p className="subtitle">
              boutique hotels provide a stay unlike any other. we can enhance that
            </p>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-half">
              <div className="card">
                <div className="card-content">
                  <p className="title is-4">full featured property management system</p>
                  <p className="subtitle is-6">amplifying the boutique hotel experience for both guest and operator</p>
                  <figure className="image">
                    <img src="/images/man-statue-arms-1.png" alt="statue of man with arms in position" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="column is-half">
              <div className="card">
                <div className="card-content">
                  <div className="content mt-4">
                    <p>we provide a comprehensive solution tailored to boutique hotels</p>
                    <ul>
                      <li>landing page for the property</li>
                      <li>embeddable booking engine</li>
                      <li>channel manager</li>
                      <li>property management system</li>
                      <li>guest app</li>
                      <div>
                        <ul>
                          <li>check-in/check-out with passport & identity verification (through <a href="https://www.veriff.com">veriff</a>)</li>
                          <li>loyalty program</li>
                          <li>stay settings & service requests</li>
                          <li>booking another stay</li>
                          <li>guest tours</li>
                        </ul>
                      </div>
                    </ul>
                  </div>
                </div>
                <footer className="card-footer">
                  <p className="card-footer-item">
                    <span>
                      $1 USD / guest / night
                    </span>
                  </p>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container box">
          <h1 className="title">who we work with</h1>
          <div className="columns">
            <div className="column">
              <p>Non Common Group</p>
              <a href="https://casaolivamx.com" target="_blank"><img src="https://stay.annwn.app/images/casa_oliva/word_logo.png" /></a>
              {/*<a href="https://casamiravalle.com.mx" target="_blank"><img src=" https://stay.annwn.app/images/casa_miravalle/logo.webp" /></a>*/}
            </div>
            <div className="column">
            </div>
          </div>
        </div>
      </section>
      <ContactForm />
      <PrivacyNotice />
    </div>
  )
}


export default LandingPage
