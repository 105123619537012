const Scale = {
  DAY: 'ALL', // TODO this should be day and we shoudl have another ALL one
  WEEK: 'week',
  MONTH: 'month',
  QUARTER: 'quarter',
  YEAR: 'year',
  DECADE: 'decade',
  CENTURY: 'century',
}

export {
  Scale
}
