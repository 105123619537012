import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import './Homepage.css';

import Navbar from './property_page/Navbar.js';
import Footer from './property_page/Footer.js';
import LandingModal from './property_page/LandingModal.js';
import PrivacyNotice from './property_page/PrivacyNotice.js';

import Image from './utilities/Image.js';

const Homepage = ({property}) => {
  return (
    <React.Fragment>
      <PrivacyNotice pt="HOME_PAGE"/>
      <LandingModal />
      <Navbar
        property={property}
      />
      <section className="hero" style={{ paddingTop: '10em'}}>
        <Image
          path='casa_oliva/hotel_image.webp'
          className='hero-image'
          alt="Hero"
        />
      </section>
      <section className="intro">
        <Image
          path='casa_oliva/puebla72_18-35.webp'
          className='intro-image'
          alt="Intro"
        />
        <div className="content">
          <h1>Home away from Home</h1>
          <h6>A better way to travel, stay at the heart of la Roma Norte, and enjoy a unique stay at our fully equipped apartments, while visiting Mexico City</h6>
        </div>
      </section>
      <section className="grid-section">
        <div className="columns is-multiline is-centered" style={{width: '100%'}}>
          <div className="column is-half" style={{maxWidth: '650px'}}>
              <figure className="image is-4by3">
                <Image path="casa_oliva/4I3A2885.webp" alt="Small 1" />
              </figure>
              <p className="title is-5" style={{marginTop: '0.5em'}}>Iconic Building</p>
              <p className="subtitle is-6">Enjoy the perks of staying at a fully renovated tenement house from 1939.</p>
          </div>
          <div className="column is-half" style={{maxWidth: '650px'}}>
              <figure className="image is-4by3">
                <img src="https://stay.annwn.app/images/casa_oliva/patio.webp" alt="Small 2" />
              </figure>
              <p className="title is-5" style={{marginTop: '0.5em'}}>Main Patio</p>
              <p className="subtitle is-6">A unique enclave surrounded by trees and vegetation that will make you feel calm and relaxed.</p>
          </div>
        </div>
        <div className="columns is-multiline is-centered" style={{width: '100%'}}>
          <div className="column is-half" style={{maxWidth: '650px'}}>
              <figure className="image is-4by3">
                <img src="https://stay.annwn.app/images/casa_oliva/gym.webp" alt="Small 3" />
              </figure>
              <p className="title is-5" style={{marginTop: '0.5em'}}>Gym</p>
              <p className="subtitle is-6">Keep your energy up and exercise at our fully equipped gym.</p>
          </div>
          <div className="column is-half" style={{maxWidth: '650px'}}>
              <figure className="image is-4by3">
                <img src="https://stay.annwn.app/images/casa_oliva/sala.webp" alt="Small 4" />
              </figure>
              <p className="title is-5" style={{marginTop: '0.5em'}}>Terrace</p>
              <p className="subtitle is-6">Whether you need to work or just chill our terrace is the perfect place to be.</p>
          </div>
        </div> 
      </section>
      <hr className="section-separator" />
      <Footer />
    </React.Fragment>
  )
}

export default Homepage
