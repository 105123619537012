import React from "react"
import PropTypes from "prop-types"

const SERVER = "https://staging.channex.io"

const PropertiesPage = ({properties}) => {
  console.log(properties)
  const propertyObjects = properties.map((property) => {
    return (
      <iframe
        src={`${SERVER}/auth/exchange?oauth_session_key=${property.channex_one_time_code}&app_mode=headless&redirect_to=/channels&property_id=${property.channex_id}`}
        style={{height: '75em', width: '75em'}}
      >
      </iframe>
    )
  })
  return (
    <React.Fragment>
      {propertyObjects}
    </React.Fragment>
  )
}


export default PropertiesPage
