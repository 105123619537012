// src/components/annwn/PrivacyNotice.js
import React, { useEffect, useState } from 'react';

import Cookies from 'js-cookie';

import initMixpanel from '../utilities/mixpanel.js';

const PRIVACY_COOKIE = 'privacy_cookie';

const GoogleTagManager = () => {
  useEffect(() => {
    // Create the script element for the gtag.js library
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = 'https://www.googletagmanager.com/gtag/js?id=AW-16647336377';

    // Append the first script tag into the document head
    document.head.appendChild(script1);

    // Create another script element for the gtag() function
    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-16647336377');
    `;

    // Append the second script tag into the document head
    document.head.appendChild(script2);

    // Clean up the script tags when the component unmounts
    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []);

  return null; // This component does not render anything
};

const PrivacyNotice = ({}) => {
  const mixpanel = initMixpanel('8228216aa14fcbb07db16364906636b6');
  const [isVisible, setIsVisible] = useState(false);

  const handleClose = () => {
    setIsVisible(false);
    Cookies.set(PRIVACY_COOKIE, 'false', { expires: 90 })
  };

  const handleAccept = () => {
    // Handle the acceptance logic here, e.g., set a cookie or localStorage item
    setIsVisible(false);
    Cookies.set(PRIVACY_COOKIE, 'true', { expires: 90 })
  };

  useEffect(() => {
    const privacy = Cookies.get(PRIVACY_COOKIE)

    if (privacy == undefined) {
      setIsVisible(true);
    } else if (privacy == 'true') {
      mixpanel.opt_in_tracking();

      /* Google tracking */
      // Create the script element for the gtag.js library
      const script1 = document.createElement('script');
      script1.async = true;
      script1.src = 'https://www.googletagmanager.com/gtag/js?id=AW-16647336377';

      // Append the first script tag into the document head
      document.head.appendChild(script1);

      // Create another script element for the gtag() function
      const script2 = document.createElement('script');
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-16647336377');
      `;

      // Append the second script tag into the document head
      document.head.appendChild(script2);

      // Clean up the script tags when the component unmounts
      return () => {
        document.head.removeChild(script1);
        document.head.removeChild(script2);
      };
      
      setIsVisible(false);
    } else {
      setIsVisible(false);
    }
  }, [])

  if (!isVisible) return null;

  return (
    <div className="privacy-notice">
      <button className="delete top-right is-large" aria-label="close" onClick={handleClose}></button>
      <div className="annwn-privacy-content">
        <h3 className="subtitle">Privacy Notice</h3>
        <p style={{fontSize: 'small'}}>
          Our website uses tracking technologies to enhance your browsing experience and provide personalized ads.
          This may involve collecting information about your visit, such as pages viewed, visit time, IP address, and browser details.
        </p>
        <p style={{fontSize: 'small'}}>
          For more information on how we handle your data, please see our <a href="https://stay.annwn.app/terms_and_conditions_privacy_policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
        </p>
        <button className="annwn-accept-button" onClick={handleAccept}>Accept</button>
      </div>
    </div>
  );
};

export default PrivacyNotice;
