import React, { useState } from "react"
import styled from 'styled-components';

const StyledLink = styled.a`
  color: inherit;           // Keep the link color the same as the surrounding text
  text-decoration: underline;    // No underline by default

  &:hover {
    text-decoration: underline; // Underline on hover
  }
`;

const NonCommonGroupLandingPage = () => {
  return (
    <div className="hero is-fullheight is-flex is-flex-direction-column">
      <div className="container has-text-centered is-flex-grow-1 is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
        <img
          src="https://stay.annwn.app/images/non_common_group/logo.png"
          alt="Main Logo"
          style={{ maxWidth: '350px', marginBottom: '3rem'}}
        />
        <div className="columns is-vcentered">
          <div className="column is-narrow">
            <a href="https://www.instagram.com/trucocafemx/">
              <img
                src="https://stay.annwn.app/images/truco_cafe/logo.webp"
                alt="truco cafe logo"
                style={{ height: '8em', objectFit: 'contain' }}
              />
            </a>
          </div>
          <div className="column is-narrow">
            <a href="https://casaolivamx.com">
              <img
                src="https://stay.annwn.app/images/casa_oliva_logo.png"
                alt="Casa Oliva Logo"
                style={{ height: '8em', objectFit: 'contain' }}
              />
            </a>
          </div>
          <div className="column is-narrow">
            <a href="https://casamiravalle.com.mx/">
              <img
                src="https://stay.annwn.app/images/casa_miravalle/2_line_logo.png"
                alt="Casa Miravalle Logo"
                style={{ height: '8em', objectFit: 'contain' }}
              />
            </a>
          </div>
        </div>
      </div>

      {/* Bottom right corner text and icon */}
      <div style={{ paddingBottom: '2rem', alignSelf: 'end', paddingRight: '2rem', paddingTop: '2rem', display: 'flex' }}>
        <span style={{ marginRight: '0.5rem', fontSize: '1em', fontWeight: 'bold' }}>Built with <StyledLink href="https://stay.annwn.app">annwn stay</StyledLink></span>
        <img
          src="https://stay.annwn.app/images/icon.png"
          alt="Annwn Stay Icon"
          style={{ height: '1em', objectFit: 'contain', alignSelf: 'center'}}
        />
      </div>
    </div>
  );
}

export default NonCommonGroupLandingPage
