import React, { useState } from "react"

import { useForm } from 'react-hook-form';

import { Client } from "../utils/Client";

function LoginForm() {
  const { register, watch, handleSubmit, formState: { errors, isValid } } = useForm({mode: 'onChange'});
  const [ loginWithPassword, setLoginWithPassword ] = useState(false);

  const onSubmit = data => {
    Client.getSession(data).then(resp => {
      window.location.href = '/'
    }).catch(err => { 
      console.log('wrong password');
    });
  };

  const email = watch('email');
  const sendMagicLink = () => {
    Client.sendMagicLink({ email })
  }

  console.log(email)

  return (
    <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <div className="columns is-centered">
        <div className="column">
          <form onSubmit={handleSubmit(onSubmit)} className="box">
            <div className="image container " style={{ margin: '0 auto', maxWidth: '20em' }}>
              <img src="/images/logo.png" alt="logo" />
            </div>
            <div className="field">
              <label className="label">Email</label>
              <div className="control">
                <input
                  className={`input ${errors.email && 'is-danger'}`}
                  type="email"
                  placeholder="e.g. alex@example.com"
                  {...register('email', { required: true })}
                />
                {errors.email && (
                  <p className="help is-danger">Email is required</p>
                )}
              </div>
            </div>

            { loginWithPassword && <div className="field">
              <label className="label">Password</label>
              <div className="control">
                <input
                  className={`input ${errors.password && 'is-danger'}`}
                  type="password"
                  placeholder="********"
                  {...register('password', { required: true })}
                />
                {errors.password && (
                  <p className="help is-danger">Password is required</p>
                )}
              </div>
            </div> } 
            <div className="field is-grouped">
              <div className="control">
                <button disabled={email === undefined} className={"button" + (loginWithPassword ? "" : " is-primary")} type="submit" onClick={sendMagicLink}>Get Login Link</button>
              </div>
              {/*
              <div className="control" style={{ marginLeft: 'auto' }}>
                <button className="button is-light" type="button" onClick={() => { setLoginWithPassword(true) }}>
                  Login With Password
                </button>
              </div>
            </div>
            <div>
              <div className="control" style={{ marginLeft: 'auto' }}>
                <button className="button is-light" type="button" onClick={() => { window.location.href='/forgot-password'; }}>
                  Forgot Password?
                </button>
              </div>
              */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
