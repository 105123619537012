import React from "react"
import PropTypes from "prop-types"

import Navbar from './property_page/Navbar.js';
import Footer from './property_page/Footer.js';
import './FindUs.css';
import LandingModal from './property_page/LandingModal.js';
import PrivacyNotice from './property_page/PrivacyNotice.js';

const FindUs = ({property}) => {
  const whatsappNumber = `https://wa.me/${property.contact_number.replaceAll(' ', '')}`;
  return (
    <React.Fragment>
      <PrivacyNotice pt="TRACKING"/>
      <LandingModal />
      <Navbar
        property={property}
      />
      <section className="section" style={{paddingTop: '10em'}}>
        <div className="columns">
          <div className="column">
            <div className="box content-box">
              <h1 className="title">Chat with Us</h1>
              <p>
                <a href={whatsappNumber} className="button is-rounded is-whatsapp" target="_blank" rel="noopener noreferrer">
                  <span className="icon">
                    <i className="fab fa-whatsapp"></i>
                  </span>
                  <span>Message us</span>
                </a>
              </p>
              <p>
                <a href={property.instagram_url} className="button is-rounded is-instagram" target="_blank" rel="noopener noreferrer">
                  <span className="icon">
                    <i className="fab fa-instagram"></i>
                  </span>
                  <span>Instagram</span>
                </a>
              </p>
              <p className="address">
                <strong>Address:</strong><br/>
                Puebla 74, Roma Norte,<br />
                Mexico City, Mexico
              </p>
              <p className="address">
                <strong>Contact:</strong><br/>
                {property.contact_number}
              </p>
            </div>
          </div>
          <div className="column">
            <div className="box" style={{height: '100%'}}>
              <iframe
                title="Google Maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.3456813842394!2d-99.16496728469327!3d19.41948094614043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff3e2cbe002d%3A0x858882a4b86a3df7!2sPuebla%2074%2C%20Roma%20Norte%2C%2006700%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX%2C%20Mexico!5e0!3m2!1sen!2s!4v1629997600130!5m2!1sen!2s"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  )
}


export default FindUs
